( function( $ ) {

    function tcoSetSliderHeights() {
        $('.tco-slider .slides').each(function() {
            var maxHeight = 0;

            $(this).find('.slide > div').each(function(j, child) {
                maxHeight = ($(child).outerHeight() > maxHeight ? $(child).outerHeight() : maxHeight);
            });
            $(this).parent().css("min-height", maxHeight)
        });
    }

    tcoSetSliderHeights();

    $(document).resize( tcoSetSliderHeights() );

    setTimeout(function() {
        tcoSetSliderHeights();
    }, 2000 );

} )( jQuery );