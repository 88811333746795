(function($) {

    // Grab the content from the static header
    var $static_header = $('.site-header--static .site-header__row').clone();

    // Add the content into the fixed header
    $('.site-header--fixed .site-header__inner').html($static_header);

    // Re-init the menu toggle
    $('.site-header--fixed [data-toggle-offside]').click(function( event ) {
        siteOffcanvas.toggle();

        event.preventDefault();
        return false;
    });

})(jQuery);